import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import WalletConnect from "../WalletConnect";
import { useSmallScreen } from "../../utils/utils";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  AppBar: {
    marginTop: "40px",
    background: "transparent",
  },
  logo: {
    paddingLeft: "100px",
    cursor: "pointer",
  },
  buttonContainer: {
    paddingRight: "100px",
  },
  text: {
    fontSize: "18px",
    fontWeight: 900,
    textTransform: "capitalize",
  },
  tab: {
    color: "white",
    fontSize: 20,
  },
  indicator: {
    backgroundColor: "#5C1864",
  },
});

const TopBar = () => {
  const classes = useStyles();
  const smallScreen = useSmallScreen();

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.AppBar}
        position="static"
        elevation={0}
        style={{ marginTop: 20 }}
      >
        <Grid
          container
          direction="row"
          justify={smallScreen ? "center" : "flex-end"}
          alignItems="center"
        >
          {!smallScreen && (
            <Grid item className={classes.buttonContainer}>
              <Grid container justify="center" alignItems="center" spacing={4}>
                <Grid item>
                  <WalletConnect />
                </Grid>
              </Grid>
            </Grid>
          )}
          {smallScreen && (
            <>
              <Grid container justify="center" alignItems="center" spacing={4}>
                <Grid item>
                  <WalletConnect />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </AppBar>
    </div>
  );
};

export default TopBar;
