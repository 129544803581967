import React, { useEffect } from "react";
import { HashRouter, Route } from "react-router-dom";
import { useSnackbar } from "notistack";
import SnackbarUtils from "./utils/SnackbarUtils";
import NavigationFrame from "./components/NavigationFrame";
import ClaimPage from "./pages/ClaimPage";
import AdminPage from "./pages/AdminPage";

export default function Routes() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    SnackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);
  return (
    <HashRouter>
      <NavigationFrame>
        <Route exact path="/" component={ClaimPage} />
        <Route exact path="/admin" component={AdminPage} />
      </NavigationFrame>
    </HashRouter>
  );
}
